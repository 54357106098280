import React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import { graphql } from "gatsby";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const Page = (props) => {
  const { data, errors } = props;

  return (
    <Layout>
      <SearchEngineOptimization
        title="Privacy Policy | RealSynch"
        description="Learn how RealSynch protects your data and privacy. Read our comprehensive privacy policy for full details on data security and compliance."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="md:pb-30 pt-12 pb-20 md:pt-16">
        <div className="container">
          <StyledContent>
            <h1>Legal Privacy Policy</h1>
            <p>Date of Last Revision: April 26, 2018</p>
            <p>
              Welcome to the web site (the "Site") of ReWatt, Inc. (“ReWatt”,
              "Realsynch", "we", "us" and/or "our"). This Site is operated by
              ReWattInc and has been created to provide information about our
              company and its content and data linking platform and related
              services (together with the Site, the "Service(s)") to our Service
              visitors ("you", "your"). This Privacy Policy sets forth Real
              Synch's policy with respect to information including personally
              identifiable data ("Personal Data") and other information that is
              collected from visitors to the Site and Services.
            </p>

            <h3>Third-Party Service Integration</h3>

            <p>
              Our Service allows you to integrate and create commands for
              various online third-party services ("Third-Party Services"). In
              order to take advantage of this feature, you may need to
              authenticate, register for or log into Third-Party Services
              through the Service or on the websites of their respective
              providers. When you enable linking between or log in to
              Third-Party Services through the Service, we will collect relevant
              information necessary to enable the Service to access that
              Third-Party Service and your data and content contained within
              that Third-Party Service ("Login Credentials"). We store your
              Login Credentials long enough to enable linking to the Third-Party
              Service, or in some instances, to continue the link.
            </p>

            <p>
              When you enable the Service to link content and data between
              Third-Party Services, the Third-Party Services will provide us
              with access to certain information that you may have provided to
              the Third-Party Services, and we will use, store and disclose such
              information in accordance with this Privacy Policy and the rules
              you set to govern the linking. However, please remember that the
              manner in which Third-Party Services use, store and disclose your
              information is governed by the policies of such Third-Party
              Services, and Realsynch shall have no liability or responsibility
              for the privacy practices or other actions of any Third-Party
              Services that may be enabled within the Service.
            </p>

            <p>
              We may retain certain personally non-identifiable information
              related to the data or content linked between Third-Party Services
              (for example, date sent, link configuration, names of the
              Third-Party Services), for the purpose of improving our Services
              and as described below in the "Non-Identifiable Data" section
            </p>

            <h3>Third-Party Payment Processor</h3>

            <p>
              We use a third party payment processor to process payments made to
              us. In connection with the processing of such payments, we do not
              retain any personally identifiable information or any financial
              information such as credit card numbers. Rather, all such
              information is provided directly to our third party processor,
              Stripe, whose use of your personal information is governed by
              their privacy policy, which may be viewed at{" "}
              <a href="https://stripe.com/us/privacy">
                https://stripe.com/us/privacy.
              </a>
            </p>

            <h3>Information We Collect</h3>

            <p>
              When you interact with us through the Services, we may collect
              Personal Data and other information from you, as further described
              below:
            </p>

            <p>
              Personal Data That You Provide Through the Services: We collect
              Personal Data from you when you voluntarily provide such
              information, such as when you contact us with inquiries, respond
              to one of our surveys, register for access to the Realsynch
              Services or use certain Realsynch Services. Wherever Realsynch
              collects Personal Data we make an effort to provide a link to this
              Privacy Policy. You can choose at any time to opt out by
              contacting support at contact@RealSynch.com, you can also contact
              us to opt out of your information being used for purposes other
              than it was originally collected.
            </p>

            <p>
              By voluntarily providing us with Personal Data, you are consenting
              to our use of it in accordance with this Privacy Policy. If you
              provide Personal Data to the Services, you acknowledge and agree
              that such Personal Data may be transferred from your current
              location to the offices and servers of Realsynch and the
              authorized third parties referred to herein located in the United
              States. The Personal Information that you provide can be accessed
              at any time through your account or you can contact our customer
              support at contact@RealSynch.com if you need help accessing your
              account.
            </p>

            <p>
              Cookies: In operating the Services, we may use a technology called
              "cookies." A cookie is a piece of information that the computer
              that hosts our Services gives to your browser when you access the
              Services. Our cookies help provide additional functionality to the
              Services and help us analyze Services usage more accurately. For
              instance, our Services may set a cookie on your browser that
              allows you to access the Services without needing to remember and
              then enter a password more than once during a visit to the
              Services. In all cases in which we use cookies, we will not
              collect Personal Data except with your permission. On most web
              browsers, you will find a "help" section on the toolbar. Please
              refer to this section for information on how to receive
              notification when you are receiving a new cookie and how to turn
              cookies off. We recommend that you leave cookies turned on because
              they allow you to take advantage of some of the Services'
              features. Aggregated Personal Data: In an ongoing effort to better
              understand and serve the users of the Realsynch Services, Real
              Synch often conducts research on its customer demographics,
              interests and behavior based on the Personal Data and other
              information provided to us. This research may be compiled and
              analyzed on an aggregate basis, and Realsynch may share this
              aggregate data with its affiliates, agents and business partners.
              This aggregate information does not identify you personally. Real
              Synch may also disclose aggregated user statistics in order to
              describe our services to current and prospective business
              partners, and to other third parties for other lawful purposes. We
              will keep any information that you provide to us until such time
              as you delete your account with the Realsynch Service.
            </p>

            <h3>Our Use of Your Personal Data and Other Information</h3>

            <p>
              Realsynch will not sell or rent to any third party any of the
              personal information or data that you provide to us. Realsynch
              uses the Personal Data you provide in a manner that is consistent
              with this Privacy Policy. If you provide Personal Data for a
              certain reason, we may use the Personal Data in connection with
              the reason for which it was provided. For instance, if you contact
              us by email, we will use the Personal Data you provide to answer
              your question or resolve your problem. Also, if you provide
              Personal Data in order to obtain access to the Realsynch Services,
              we will use your Personal Data to provide you with access to such
              services and to monitor your use of such services. Realsynch and
              its subsidiaries and affiliates (the "Realsynch Related
              Companies") may also use your Personal Data and other personally
              non-identifiable information collected through the Services to
              help us improve the content and functionality of the Services, to
              better understand our users and to improve the Realsynch Services.
              Realsynch and its affiliates may use this information to contact
              you in the future to tell you about services we believe will be of
              interest to you. If we do so, each communication we send you will
              contain instructions permitting you to "opt-out" of receiving
              future communications. In addition, if at any time you wish not to
              receive any future communications or you wish to have your name
              deleted from our mailing lists, please contact us as indicated
              below.
            </p>

            <p>
              If Realsynch intends on using any Personal Data in any manner that
              is not consistent with this Privacy Policy, you will be informed
              of such anticipated use prior to or at the time at which the
              Personal Data is collected.
            </p>

            <h3>Our Disclosure of Your Personal Data and Other Information</h3>

            <p>
              You can visit the Services without providing any Personal Data. If
              you choose not to provide any Personal Data, you may not be able
              to use certain Realsynch Services.
            </p>

            <p>
              Realsynch is not in the business of selling your information. We
              consider this information to be a vital part of our relationship
              with you. There are, however, certain circumstances in which we
              may share your Personal Data with certain third parties without
              further notice to you, as set forth below:
            </p>

            <p>
              Business Transfers: As we develop our business, we might sell or
              buy businesses or assets. In the event of a corporate sale,
              merger, reorganization, dissolution or similar event, Personal
              Data may be part of the transferred assets. In cases of onward
              transfer of personal information to third parties of data of EU
              and Swiss individuals received pursuant to the EU-U.S. and
              Swiss-U.S. Privacy Shield, Realsynch remains liable. Related
              Companies: We may also share your Personal Data with our Related
              Companies for purposes consistent with this Privacy Policy.
              Agents, Consultants and Related Third Parties: Realsynch, like
              many businesses, sometimes hires other companies to perform
              certain business-related functions. Examples of such functions
              include mailing information, maintaining databases and processing
              payments. When we employ another company to perform a function of
              this nature, we only provide them with the information that they
              need to perform their specific function. Legal Requirements: Real
              Synch may disclose your Personal Data if required to do so by law
              or in the good faith belief that such action is necessary to (i)
              comply with a legal obligation, (ii) protect and defend the rights
              or property of Realsynch, (iii) act in urgent circumstances to
              protect the personal safety of users of the Services or the
              public, or (iv) protect against legal liability.
            </p>

            <h3>EU-U.S. & Swiss-U.S. Privacy Shield</h3>

            <p>
              Realsynch complies with the EU-U.S. Privacy Shield Framework and
              Swiss-U.S. Privacy Shield Framework as set forth by the U.S.
              Department of Commerce regarding the collection, use, and
              retention of personal information transferred from the European
              Union and Switzerland to the United States. Realsynch has
              certified to the Department of Commerce that it adheres to the
              Privacy Shield Principles. Realsynch is subject to the
              investigative and enforcement powers of the Federal Trade
              Commission (FTC). If there is any conflict between the terms in
              this privacy policy and the Privacy Shield Principles, the Privacy
              Shield Principles shall govern. To learn more about the Privacy
              Shield program, and to view our certification, please visit{" "}
              <a href="https://www.privacyshield.gov/">
                https://www.privacyshield.gov/.
              </a>
            </p>

            <p>
              In compliance with the Privacy Shield Principles, Realsynch
              commits to resolve complaints about our collection or use of your
              personal information. EU and Swiss individuals with inquiries or
              complaints regarding our Privacy Shield policy should first
              contact Realsynch at:{" "}
              <a href="mailto:contact@RealSynch.com">contact@RealSynch.com.</a>
            </p>

            <p>
              Realsynch has further committed to refer unresolved Privacy Shield
              complaints to PrivacyTrust, an alternative dispute resolution
              provider located in the United Kingdom. If you do not receive
              timely acknowledgment of your complaint from us, or if we have not
              addressed your complaint to your satisfaction, please contact
              Communications House, 26 York Street, London, W1U 6PZ for more
              information or to file a complaint. The services of Communications
              House are provided at no cost to you. Finally, as a last resort
              and in limited situations, EU and Swiss individuals may seek
              redress from the Privacy Shield Panel, a binding arbitration
              mechanism. In cases of onward transfer of personal information to
              third parties of data of EU and Swiss individuals received
              pursuant to the EU-U.S. and Swiss-U.S. Privacy Shield, Realsynch
              remains liable.
            </p>

            <h3>Exclusions</h3>

            <p>
              This Privacy Policy does not apply to any Personal Data collected
              by Realsynch other than Personal Data collected through the
              Services. This Privacy Policy shall not apply to any unsolicited
              information you provide to Realsynch through the Services or
              through any other means. This includes, but is not limited to,
              information posted to any public areas of the Services, such as
              bulletin boards (collectively, "Public Areas"), any ideas for new
              products or modifications to existing products, and other
              unsolicited submissions (collectively, "Unsolicited Information").
              All Unsolicited Information shall be deemed to be non-confidential
              and Realsynch shall be free to reproduce, use, disclose, and
              distribute such Unsolicited Information to others without
              limitation or attribution.
            </p>

            <p>
              If you are invited to join a Realsynch team account, and you
              accept the invitation, you are agreeing that certain of your
              information will be shared with the team account holder and other
              team members. In particular, the team account holder will have
              access to your name, email address, avatar (if any) and task
              usage, and other team members will have access to your name, email
              address and avatar (if any). Any information you share via a team
              account, including Zaps you create or Third-Party Services you
              link to, will be available to all team members of the team account
              you have joined. You are solely responsible for any information
              you share via a team account, which is posted at your own risk.
            </p>

            <h3>Children</h3>

            <p>
              Realsynch does not knowingly collect Personal Data from children
              under the age of 13. If you are under the age of 13, please do not
              submit any Personal Data through the Services. We encourage
              parents and legal guardians to monitor their children's Internet
              usage and to help enforce our Privacy Policy by instructing their
              children never to provide Personal Data on the Services without
              their permission. If you have reason to believe that a child under
              the age of 13 has provided Personal Data to Realsynch through the
              Services, please contact us, and we will endeavor to delete that
              information from our databases.
            </p>

            <h3>Links to Other Web Sites</h3>

            <p>
              This Privacy Policy applies only to the Services. The Services may
              contain links to other web sites not operated or controlled by
              Realsynch (the "Third-Party Sites"). The policies and procedures
              we described here do not apply to the Third-Party Sites. The links
              from the Services do not imply that Realsynch endorses or has
              reviewed the Third-Party Sites. We suggest contacting those sites
              directly for information on their privacy policies.
            </p>

            <h3>Security</h3>

            <p>
              Realsynch takes reasonable steps to protect the Personal Data
              provided via the Services from loss, misuse, and unauthorized
              access, disclosure, alteration, or destruction. However, no
              Internet or email transmission is ever fully secure or error free.
              In particular, email sent to or from the Services may not be
              secure. Therefore, you should take special care in deciding what
              information you send to us via email. Please keep this in mind
              when disclosing any Personal Data to Realsynch via the Internet.
            </p>

            <h3>Changes to Realsynch's Privacy Policy</h3>

            <p>
              The Services and our business may change from time to time. As a
              result, at times it may be necessary for Realsynch to make changes
              to this Privacy Policy. Realsynch reserves the right to update or
              modify this Privacy Policy at any time and from time to time. If
              we do this, we will post the changes to this Privacy Policy on
              this page and will indicate at the top of this page the date these
              terms were last revised. We will also notify you, either through
              the Service user interface, in an email notification or through
              other reasonable means. Please review this policy periodically,
              and especially before you provide any Personal Data. Any such
              changes will become effective no earlier than fourteen (14) days
              after they are posted, except that changes addressing new
              functions of the Service or changes made for legal reasons will be
              effective immediately. This Privacy Policy was last updated on the
              date indicated above. Your continued use of the Service after the
              date any such changes become effective constitutes your acceptance
              of the new Privacy Policy.
            </p>

            <h3>Other Terms</h3>

            <p>
              Your access to and use of the Services is subject to the Terms of
              Service at <a href="/terms/">https://www.realsynch.com/terms/</a>
            </p>

            <h3>Contacting Realsynch</h3>

            <p>
              To keep your Personal Data accurate, current, and complete, please
              contact us as specified below. We will take reasonable steps to
              update or correct Personal Data in our possession that you have
              previously submitted via the Services. Please also feel free to
              contact us if you have any questions about Realsynch's Privacy
              Policy or the information practices of the Services. All disputes
              arising out of or relating to these Terms of Service will be
              submitted to the exclusive jurisdiction of a court of competent
              jurisdiction located in Wilmington, Delaware, and each party
              irrevocably consents to such personal jurisdiction and waives all
              objections to this venue.
            </p>

            <p>
              You may contact us as follows:{" "}
              <a href="mailto:contact@RealSynch.com">contact@RealSynch.com.</a>
            </p>
          </StyledContent>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
